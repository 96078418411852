import axios from "axios";
import { httpClient } from "./httpClient";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  countryCode: string;
  createdAt: Date;
  updatedAt: Date;
  UserRole: UserRole;
}

export interface UserRole {
  roleId: string;
  userId: string;
  Role: Role;
}

export interface Role {
  id: string;
  name: string;
}
export interface AuthProps {
  username: string;
  password: string;
}
export interface AddUserProps {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
}
export interface AuthResponse {
  user: User;
  token: string;
}

export async function login(data: AuthProps): Promise<AuthResponse> {
  const response = await httpClient.sendRequest({
    url: "users/login",
    method: "POST",
    data: data,
  });
  return response.data;
}

export async function addUser(data: AddUserProps): Promise<AuthResponse> {
  const response = await httpClient.sendRequest({
    url: "users/worker",
    method: "POST",
    data: data,
  });
  return response.data;
}

export async function getAllUser(): Promise<User[]> {
  const response = await httpClient.sendRequest({
    url: "users/all",
    method: "GET",
  });
  return response.data;
}

export async function deactiveUser(userId: string): Promise<number> {
  const response = await httpClient.sendRequest({
    url: `users/worker/${userId}/deactive`,
    method: "POST",
  });
  return response.data;
}

export async function resetPassword(
  userId: string,
  password: string
): Promise<number> {
  const response = await httpClient.sendRequest({
    url: `users/worker/${userId}/password`,
    method: "PUT",
    data: { password },
  });
  return response.data;
}

export async function getUser(): Promise<User> {
  const response = await httpClient.sendRequest({
    url: "users",
    method: "GET",
  });
  return response.data;
}
export async function getUploadUrl(
  contents: { contentType: string }[]
): Promise<{ uploadURL: string; Key: string }[]> {
  const response = await httpClient.sendRequest({
    url: "users/upload/url",
    method: "POST",
    data: {
      contents: contents,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function uploadImage(url: string, file: any): Promise<string[]> {
  const response = await axios({
    url: url,
    method: "PUT",
    data: file,
    headers: {
      "Content-Type": "image/png",
    },
  });
  return response.data;
}
