import { Button, Text } from "@chakra-ui/react";
import React from "react";

export interface PrimaryButtonProps {
  onClick?: () => void;
  title?: string;
  leftIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  rightIcon?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  disabled?: boolean;
  isLoading?: boolean;
}

export default function PrimaryButton({
  onClick,
  title,
  leftIcon,
  rightIcon,
  disabled,
  isLoading,
}: PrimaryButtonProps) {
  return (
    <Button
      isLoading={isLoading}
      disabled={disabled}
      py={7}
      borderRadius={"12px"}
      boxShadow={"lg"}
      leftIcon={leftIcon}
      // backgroundImage={require("assets/images/Rectangle1.png")}
      bgColor={"black"}
      bgSize={"cover"}
      bgPos={"center"}
      rightIcon={rightIcon}
      // rounded={"lg"}
      _hover={{
        bgColor: "gray.800",
      }}
      w={"full"}
      onClick={onClick}>
      <Text variant='title_md'>{title}</Text>
    </Button>
  );
}
