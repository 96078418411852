import { Box, Image, Stack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { destroyToken } from "../services/api/util";
import { UserContext } from "../services/userContext";

export default function Header() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <Box
      as="nav"
      position={"fixed"}
      top={0}
      backdropFilter={"blur(20px)"}
      bgColor={"white"}
      transition="all 0.2s ease"
      w={"full"}
      h={"86px"}
      borderBottom={"1px solid"}
      borderColor={"gray.300"}
      zIndex={999}
    >
      <Stack direction={"row"} align={"center"} py={4} px={6} h={"86px"}>
        <Box animation={"fadeInUpBig 1s ease"}>
          <Link to="/">
            <Image w={12} src={require("../assets/logo.png")} />
          </Link>
        </Box>
        <Box flex={1} />
        <Stack spacing={6} direction={"row"} align={"center"} w="full" pl="6">
          <Box flex={1} />
          <Link to="/quote">
            <Text
              fontWeight={"600"}
              color={"black"}
              px={1}
              _hover={{ color: "gray.400" }}
              variant="title_l"
            >
              Quote
            </Text>
          </Link>
          <Link to="/make-safe">
            <Text
              fontWeight={"600"}
              color={"black"}
              px={1}
              _hover={{ color: "gray.400" }}
              variant="title_l"
            >
              Make Safe Order
            </Text>
          </Link>
          {user?.UserRole?.Role?.name === "ADMIN" && (
            <Link to="/admin">
              <Text
                fontWeight={"600"}
                color={"black"}
                px={1}
                _hover={{ color: "gray.400" }}
                variant="title_l"
              >
                Admin
              </Text>
            </Link>
          )}
          {user?.UserRole?.Role?.name === "ADMIN" && (
            <Link to="/workers">
              <Text
                fontWeight={"600"}
                color={"black"}
                px={1}
                _hover={{ color: "gray.400" }}
                variant="title_l"
              >
                Assessor
              </Text>
            </Link>
          )}
          {user?.UserRole?.Role?.name === "ADMIN" && (
            <Link to="/trackings">
              <Text
                fontWeight={"600"}
                color={"black"}
                px={1}
                _hover={{ color: "gray.400" }}
                variant="title_l"
              >
                Trackings
              </Text>
            </Link>
          )}

          {user ? (
            <Text
              onClick={() => {
                destroyToken();
                setUser(undefined);
                navigate("/login");
              }}
              cursor={"pointer"}
              fontWeight={"600"}
              color={"black"}
              px={1}
              _hover={{ color: "gray.400" }}
              variant="title_l"
            >
              Logout
            </Text>
          ) : (
            <Link to="/login">
              <Text
                fontWeight={"600"}
                color={"black"}
                px={1}
                _hover={{ color: "gray.400" }}
                variant="title_l"
              >
                Login
              </Text>
            </Link>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
