import {
  Container,
  TableContainer,
  Table,
  TableCaption,
  useToast,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  Text,
  Stack,
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  FormLabel,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomInput from "../components/CustomInput";
import PrimaryButton from "../components/PrimaryButton";
import { authApiErrorHandler } from "../services/api/httpClient";
import {
  addUser,
  deactiveUser,
  getAllUser,
  resetPassword,
  User,
} from "../services/api/user";

export default function Worker() {
  const [data, setData] = useState<User[]>([]);
  const [username, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const handleGetUsers = async () => {
    try {
      const users = await getAllUser();
      setData(users);
    } catch (e: any) {
      const error = authApiErrorHandler(e, navigate);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const reset = () => {
    setPassword("");
    setFirstName("");
    setLastName("");
    setUserName("");
  };

  const handleDeactiveUser = async (userId: string) => {
    try {
      await deactiveUser(userId);
      toast({
        title: "Success",
        description: "Deactive Assessor Success",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      handleGetUsers();
    } catch (e) {
      const error = authApiErrorHandler(e, navigate);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleReset = async (userId: string) => {
    try {
      const password = prompt("Please enter a new password to reset");
      if (password) {
        await resetPassword(userId, password);

        toast({
          title: "Success",
          description: "Reset password succeed",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Please type in a valid password",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e) {
      const error = authApiErrorHandler(e, navigate);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const handleAddUser = async () => {
    try {
      if (!firstName || !lastName || !username || !password) {
        toast({
          title: "Missing Field",
          description: "Please fill in all required fields",
          status: "info",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      await addUser({ firstName, lastName, username, password });
      toast({
        title: "Success",
        description: "Add Assessor Success",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      handleGetUsers();
    } catch (e: any) {
      const error = authApiErrorHandler(e, navigate);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    handleGetUsers();
  }, []);
  return (
    <Container pt={12} maxW={"1400px"}>
      <Modal
        size={"xl"}
        isCentered
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
      >
        <ModalOverlay background={"transparent"} />

        <ModalContent alignItems={"center"} py={8} background={"white"}>
          <ModalCloseButton m={2} size={"md"} />

          <Stack w={"full"} px={4}>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="date">
                Username
              </FormLabel>
              <CustomInput
                maxLength={50}
                value={username}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="date">
                First Name
              </FormLabel>
              <CustomInput
                maxLength={50}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="date">
                Last Name
              </FormLabel>
              <CustomInput
                maxLength={50}
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="date">
                Password
              </FormLabel>
              <CustomInput
                maxLength={50}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Box>{" "}
            <Box w={"full"} pt={4}>
              <PrimaryButton
                onClick={() => {
                  handleAddUser();
                  setIsOpen(false);
                  reset();
                }}
                title="Add"
              />
            </Box>
          </Stack>
        </ModalContent>
      </Modal>
      <Box w={"200px"}>
        <PrimaryButton onClick={() => setIsOpen(true)} title="Add Assessor" />
      </Box>

      <TableContainer>
        <Table variant="simple">
          <TableCaption>All Assessor</TableCaption>

          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Username</Th>
              <Th>Created At</Th>
              <Th>Operation</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((n) => {
              return (
                <Tr key={n.id}>
                  <Td>{n.id}</Td>
                  <Td>{n.firstName}</Td>
                  <Td>{n.lastName}</Td>

                  <Td>{n.username}</Td>

                  <Td>{moment(n.createdAt).format("YYYY-MM-DD HH:MM")}</Td>
                  <Td>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box flex={1}>
                        <PrimaryButton
                          onClick={() => {
                            const confirm = window.confirm(
                              `Are you sure you want to deactivate username: ${n.username}`
                            );
                            if (confirm) {
                              handleDeactiveUser(n.id);
                            }
                          }}
                          title="Deactive"
                        />
                      </Box>

                      <Box flex={1}>
                        <Button
                          onClick={() => {
                            handleReset(n.id);
                          }}
                          ml={3}
                          py={7}
                          bg="red.400"
                        >
                          <Text variant="title_md">Reset Password</Text>
                        </Button>
                      </Box>
                    </Box>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
}
