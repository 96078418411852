import {
  Stack,
  FormControl,
  FormLabel,
  Text,
  Box,
  useToast,
  Image,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../components/CustomInput";
import PrimaryButton from "../components/PrimaryButton";
import { authApiErrorHandler } from "../services/api/httpClient";
import { login } from "../services/api/user";
import { saveToken } from "../services/api/util";
import { UserContext } from "../services/userContext";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setUser } = useContext(UserContext);

  const [error, setError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      setError("");
      setIsLoading(true);
      const response = await login({ username, password });
      toast({
        title: "Login Success",
        description: "Welcome",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      saveToken(response.token);
      setUser(response.user);

      navigate("/");
    } catch (e) {
      const error = authApiErrorHandler(e, navigate);
      setError(error.message ?? "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack
      overflow={"hidden"}
      borderRadius={12}
      direction={"row"}
      boxShadow={"lg"}
      w={"100%"}
      background={"white"}
      maxWidth={"528px"}
      m={"auto"}
      mt={6}
    >
      <Box py={12} flex={1}>
        <Box p={4} margin={"auto"} maxW={425}>
          <Stack flex={1} align={"center"}>
            <Link to={"/"}>
              <Image h={16} src={require("../assets/logo-ccr.png")} />
            </Link>
            <Text
              pt={4}
              fontWeight={"bold"}
              color={"black"}
              variant="title_3xl"
            >
              Log in
            </Text>

            <FormControl color={"gray.400"} isRequired>
              <Box w={"full"} pt={4}>
                <FormLabel color={"black"} htmlFor="email">
                  Username
                </FormLabel>
                <CustomInput
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  type={"email"}
                />
              </Box>
              <Box w={"full"} pt={4}>
                <FormLabel color={"black"} htmlFor="password">
                  Password
                </FormLabel>
                <CustomInput
                  value={password}
                  type={"password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Box>

              <Box pt={4} pb={8} w={"full"}>
                <Box h={8}>
                  {error && (
                    <Text noOfLines={1} color={"red.300"} variant={"title_md"}>
                      {error}
                    </Text>
                  )}
                </Box>
                <PrimaryButton
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={() => handleLogin()}
                  title="Log in"
                />
              </Box>
            </FormControl>
            {/* <Stack
              w={"full"}
              justifyContent={"space-between"}
              direction={"row"}
            >
              <Link to={"/forgot-password"}>
                <Text color={"blue.600"} variant="caption">
                  Forgot password?
                </Text>
              </Link>
              <Box flex={1} />
              <Link to={"/signup"}>
                <Text color={"blue.600"} variant="caption">
                  Don't have an account? Sign up
                </Text>
              </Link>
            </Stack> */}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}
