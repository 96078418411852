import { httpClient } from "./httpClient";
import { User } from "./user";

export enum ETrackingStatus {
  PAID = "PAID",
  UNPAID = "UNPAID",
}

export interface Tracking {
  id: number;
  address: string;
  jobType: string;
  invoiceNo: string;
  creditor: string;
  pm: string;
  materialFee: number;
  amount: number;
  status: ETrackingStatus;
  createdAt: Date;
  updatedAt: Date;
  User: User;
}

export async function addTracking(data: any): Promise<Tracking> {
  const response = await httpClient.sendRequest({
    url: "trackings",
    method: "POST",
    data: data,
  });
  return response.data;
}

export async function updateTracking(data: any): Promise<Tracking> {
  const response = await httpClient.sendRequest({
    url: `trackings/${data.id}`,
    method: "PUT",
    data: data,
  });
  return response.data;
}

export async function deleteTracking(id: number): Promise<Tracking> {
  const response = await httpClient.sendRequest({
    url: `trackings/${id}`,
    method: "DELETE",
  });
  return response.data;
}

export async function getTrackings(): Promise<Tracking[]> {
  const response = await httpClient.sendRequest({
    url: "trackings",
    method: "GET",
  });
  return response.data;
}
