import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Container,
  useToast,
  Text,
  Box,
  IconButton,
  FormLabel,
} from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  apiErrorHandler,
  authApiErrorHandler,
} from "../services/api/httpClient";
import { downloadQuote, getQuotes, Quote } from "../services/api/quote";
import Fuse from "fuse.js";
import CustomInput from "../components/CustomInput";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
const options = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  // threshold: 0.6,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["id", "siteAddress"],
};

export default function Admin() {
  const navigate = useNavigate();

  const toast = useToast();
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState<Quote[]>([]);
  const [rawData, setRawData] = useState<Quote[]>([]);
  const [isLoading, setIsLoading] = useState<number[]>([]);
  const handleGetQuote = async () => {
    try {
      const response = await getQuotes();
      setRawData(response);
      setData(response);
    } catch (e) {
      const error = authApiErrorHandler(e, navigate);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleDownloadQuote = async (id: number) => {
    try {
      await downloadQuote(id);
      const index = rawData.findIndex((n) => n.id === id);
      if (index !== -1) {
        const next = [...rawData];
        next[index].isDownloaded = true;
        setRawData(next);
      }
      const indexOfData = data.findIndex((n) => n.id === id);
      if (indexOfData !== -1) {
        const next = [...data];
        next[indexOfData].isDownloaded = true;
        setData(next);
      }
    } catch (e) {
      const error = authApiErrorHandler(e, navigate);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    handleGetQuote();
  }, []);
  const getFile = async (link: string, quote: any) => {
    try {
      setIsLoading([quote.id, ...isLoading]);
      const response = await axios(link, {
        responseType: "blob",
      });
      const blob = response.data;
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      if (quote.type === "Make Safe Order") {
        a.download = `Tax Invoice #${quote.id}; ${quote.siteAddress}.docx`;
      } else {
        a.download = `Quotation #${quote.id}; ${quote.siteAddress}.docx`;
      }

      a.click();
    } catch (e) {
    } finally {
      setIsLoading(isLoading.filter((n) => n !== quote.id));
    }
  };
  const handleSearch = (text: string) => {
    if (text === "") {
      setData(rawData);
      return;
    }
    const options = {
      isCaseSensitive: false,
      includeScore: true,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      minMatchCharLength: 1,
      location: 0,
      threshold: 0.4,
      distance: 5,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: ["id", "siteAddress"],
    };

    const fuse = new Fuse(rawData, options);
    const result = fuse.search(text);
    setData(result.map((n) => n.item));
    console.log(result);
  };
  return (
    <Container pt={12} maxW={"1800px"}>
      <Box my={4} maxW={300}>
        <CustomInput
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          rightElement={
            <IconButton
              onClick={() => {
                handleSearch(searchText);
              }}
              size="lg"
              aria-label="search"
              icon={<SearchIcon color={"black"} />}
            />
          }
        />
      </Box>

      <TableContainer>
        <Table variant="simple">
          <TableCaption>All quotes</TableCaption>
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Date</Th>
              <Th>Site Address</Th>
              <Th>Type</Th>
              <Th>Submitted by</Th>
              <Th>Username</Th>
              <Th>Created At</Th>
              <Th>Downloaded</Th>
              <Th>Operation</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((n) => {
              return (
                <Tr key={n.id}>
                  <Td>{n.id}</Td>
                  <Td>{n.date}</Td>
                  <Td>{n.siteAddress}</Td>
                  <Td>{n.type || "Normal"}</Td>
                  <Td>{`${n.User.firstName} ${n.User.lastName}`}</Td>
                  <Td>{n.User.username}</Td>
                  <Td>{moment(n.createdAt).format("YYYY-MM-DD HH:MM")}</Td>
                  <Td>
                    <p style={{ color: "red" }}>
                      {n.isDownloaded ? "Downloaded" : ""}
                    </p>
                  </Td>
                  <Td>
                    <Box
                      onClick={() => {
                        !isLoading.includes(n.id) && getFile(n.link || "", n);
                        !n.isDownloaded && handleDownloadQuote(n.id);
                      }}
                      cursor={"pointer"}
                    >
                      <Text variant={"title_l"} color={"blue.400"}>
                        {isLoading.includes(n.id) ? "Loading" : "Download"}
                      </Text>{" "}
                    </Box>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>Id</Th>
              <Th>Date</Th>
              <Th>To Address</Th>
              <Th>Type</Th>
              <Th>Submitted by</Th>
              <Th>Username</Th>
              <Th>Created At</Th>
              <Th>Downloaded</Th>
              <Th>Operation</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Container>
  );
}
