import { Stack, Box, Text, Button } from "@chakra-ui/react";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  return (
    <Stack
      overflow={"hidden"}
      borderRadius={12}
      direction={"row"}
      boxShadow={"lg"}
      w={"100%"}
      background={"white"}
      maxWidth={"528px"}
      m={"auto"}
      mt={6}
      pb={12}
    >
      <Box py={12} flex={1}>
        <Box p={4} margin={"auto"} maxW={"360px"}>
          <Text variant={"title_2xl"} textAlign={"center"} pb={6}>
            Choose type
          </Text>
          <PrimaryButton onClick={() => navigate("/quote")} title="Quote" />
          <Text variant={"title_2xl"} textAlign={"center"} my={12}>
            OR
          </Text>
          <PrimaryButton
            onClick={() => navigate("/make-safe")}
            title="Make Safe Order"
          />
        </Box>
      </Box>
    </Stack>
  );
}
