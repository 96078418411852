import React, { useEffect } from "react";
import "./App.css";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Quote from "./screens/Quote";
import Header from "./components/Header";
import customTheme from "./theme";
import Login from "./screens/Login";
import { useUserContext, UserContext } from "./services/userContext";
import { destroyToken, getToken } from "./services/api/util";
import { getUser } from "./services/api/user";
import { useContext } from "react";
import Admin from "./screens/Admin";
import Worker from "./screens/Worker";
import Tracking from "./screens/Tracking";
import MakeSafeOrder from "./screens/MakeSafeOrder";
import Home from "./screens/Home";
const routes = [
  { path: "/", element: <Home /> },
  { path: "/quote", element: <Quote /> },
  { path: "/make-safe", element: <MakeSafeOrder /> },
  { path: "/login", element: <Login /> },
  { path: "/admin", element: <Admin /> },
  { path: "/workers", element: <Worker /> },
  { path: "/trackings", element: <Tracking /> },
];
function App() {
  const { user, setUser } = useUserContext();
  return (
    <ChakraProvider theme={customTheme}>
      <BrowserRouter>
        <UserContext.Provider value={{ user, setUser }}>
          <BaseContainer />
        </UserContext.Provider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

function BaseContainer() {
  const token = getToken();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const handleGetUser = async () => {
    try {
      const user = await getUser();
      setUser(user);
    } catch (error) {
      destroyToken();
      navigate("/login");
    }
  };
  useEffect(() => {
    if (token) {
      handleGetUser();
    } else {
      navigate("/login");
    }
  }, [token, navigate]);
  return (
    <>
      <Header />
      <Box pt={"86px"}>
        <Routes>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            );
          })}
        </Routes>
      </Box>
    </>
  );
}

export default App;
