import React, { useState } from "react";
import { User } from "./api/user";

export const useUserContext = () => {
  const [user, setUser] = useState<User | undefined>(undefined);

  return {
    user,
    setUser,
  };
};
export const UserContext = React.createContext<any>(undefined);
