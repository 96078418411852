import {
  Stack,
  Box,
  Text,
  Button,
  Progress,
  Badge,
  FormControl,
  FormLabel,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Textarea,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import PrimaryButton from "../components/PrimaryButton";
import { useEffect, useState } from "react";
import { apiErrorHandler } from "../services/api/httpClient";
import { ChevronDownIcon } from "@chakra-ui/icons";
import moment from "moment";
import CustomInput from "../components/CustomInput";
import { addQuote } from "../services/api/quote";
import { cacheKey, ownerList, realestateList, strataList } from "./Quote";
import { useNavigate } from "react-router-dom";
import { getUploadUrl, uploadImage } from "../services/api/user";
const metWithTypes = [
  "the tenant",
  "the owner",
  "the resident",
  "the unit occupant",
  "custom answer",
];

const roomTypes = [
  "the livingroom",
  "the bedroom",
  "the lounge room",
  "the garage",
  "the kitchen",
  "the attic",
  "custom answer",
];

const roofTypes = [
  "the single-storey roof",
  "the 2-storey roof",
  "the 3-storey roof",
  "the 4-storey roof",
  "custom answer",
];

export default function MakeSafeOrder() {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [isLoading, setIsLoading] = useState(false);
  const [owner, setOwner] = useState("");
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<
    { imageUrl: string; width: number; height: number }[]
  >([]);
  const [makeSafeDescription, setMakeSafeDescription] = useState("");
  const [ownerChoice, setOwnerChoice] = useState("Press to Select");
  const [realestateChoice, setRealestateChoice] = useState(realestateList[0]);
  const [strataChoice, setStrataChoice] = useState(strataList[0]);
  const [realestate, setRealestate] = useState("Press to Select");
  const [strata, setStrata] = useState("Press to Select");
  const [strataPlan, setStrataPlan] = useState("");

  const [landlordPhone, setLandLordPhone] = useState("");
  const [landlordEmail, setLandLordEmail] = useState("");
  const [roofChoice, setRoofChoice] = useState(roofTypes[0]);
  const [metWithChoice, setMetWithChoice] = useState(metWithTypes[0]);
  const [roomChoice, setRoomChoice] = useState(roomTypes[0]);
  const [roofType, setRoofType] = useState("");
  const [roomType, setRoomType] = useState("");
  const [metWith, setMetWith] = useState("");
  const [jobNo, setJobNo] = useState("");
  const [open, setOpen] = useState(false);
  const [siteAddress, setSiteAddress] = useState("");
  const isNextDisabled = () => {
    switch (step) {
      case 1:
        return !imageUrls.length;
      case 2:
        return !(
          date &&
          ownerChoice &&
          ownerChoice !== "Press to Select" &&
          siteAddress
        );
      case 3:
        return !makeSafeDescription;
      default:
        return true;
    }
  };
  useEffect(() => {
    if (images.length) {
      handleImageUpload(images);
    }
  }, [images.length]);

  const handleImageUpload = async (files: File[]) => {
    try {
      setLoading(true);
      const uploadUrls = await getUploadUrl(
        files.map((file) => {
          return {
            contentType: "image/png",
          };
        })
      );
      const results = await Promise.all(files.map((n) => addImageProcess(n)));
      console.log(results);
      await Promise.all(
        uploadUrls.map((n, i) => uploadImage(n.uploadURL, files[i]))
      );
      setImageUrls(
        uploadUrls.map((n, i) => ({
          imageUrl: n.uploadURL.split("?")[0],
          width: results[i].width,
          height: results[i].height,
        }))
      );
      setStep(2);
    } catch (error: any) {
      alert("upload image failed");
      alert(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const generateMakeSafeDescription = () => {
    setMakeSafeDescription(
      `We visited the site on ${moment(new Date()).format(
        "dddd"
      )} morning and met with ${
        metWithChoice === "custom answer" ? metWith : metWithChoice
      }, who showed us the leak in ${
        roomChoice === "custom answer" ? roomType : roomChoice
      }.\n\nWe gained access to ${
        roofChoice === "custom answer" ? roofType : roofChoice
      } to inspect it.\n\nAbove the leak area, we discovered <type roofing defect>. Therefore, we <type what you did to patch it>;.\n\nIn addition, <type roofing defect>. Therefore, we <type what you did to patch it>.\n\nFinally, <type roofing defect>. Therefore, we <type what you did to patch it>.\n\nHowever, this work is a temporary repair and we cannot warranty this leak.\n\nWe provide a quotation (attached) for further roof maintenance and repair work.`
    );
  };
  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <Stack>
            <Text mt={2}>Upload All Make Safe Photos</Text>
            <input
              onChange={(e) => {
                console.log(e.target.files);
                setImages(Array.from(e.target.files as FileList));
              }}
              multiple
              type="file"
              accept=".png, .jpg, .jpeg"
              title="file"
            />
            {loading && <Text mt={2}>Uploading images...</Text>}
          </Stack>
        );
      case 2:
        return (
          <FormControl color={"gray.400"} isRequired>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="date">
                Quote Date
              </FormLabel>
              <CustomInput
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                type={"date"}
              />
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel htmlFor="The Owner" color={"black"}>
                Owner or Owner's Corporation
              </FormLabel>
              <Menu>
                <MenuButton
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  {ownerChoice}
                </MenuButton>
                <MenuList>
                  {ownerList.map((n) => {
                    return (
                      <MenuItem
                        color={"black"}
                        key={n}
                        onClick={() => setOwnerChoice(n)}
                      >
                        {n}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
              {ownerChoice === ownerList[0] && (
                <Box mt={2}>
                  <Box w={"full"} pb={4}>
                    <FormLabel htmlFor="Real Estate" color={"black"}>
                      Strata
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        background={"black"}
                        color={"white"}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                      >
                        {strataChoice}
                      </MenuButton>
                      <MenuList maxHeight={"50vh"} overflowY={"scroll"}>
                        {strataList.map((n) => {
                          return (
                            <MenuItem
                              color={"black"}
                              key={n}
                              onClick={() => {
                                setStrataChoice(n);
                              }}
                            >
                              {n}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                    {strataChoice === strataList[strataList.length - 1] && (
                      <CustomInput
                        value={strata}
                        onChange={(e) => {
                          setStrata(e.target.value);
                        }}
                      />
                    )}
                  </Box>
                  <FormLabel color={"black"} htmlFor="date">
                    Strata Plan Number
                  </FormLabel>
                  <CustomInput
                    value={strataPlan}
                    maxLength={50}
                    onChange={(e) => {
                      setStrataPlan(e.target.value);
                    }}
                  />
                </Box>
              )}
              {ownerChoice === ownerList[1] && (
                <Box mt={2}>
                  <Box w={"full"} pb={4}>
                    <FormLabel htmlFor="Real Estate" color={"black"}>
                      Real Estate
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        background={"black"}
                        color={"white"}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                      >
                        {realestateChoice}
                      </MenuButton>
                      <MenuList maxHeight={"50vh"} overflowY={"scroll"}>
                        {realestateList.map((n) => {
                          return (
                            <MenuItem
                              color={"black"}
                              key={n}
                              onClick={() => {
                                setRealestateChoice(n);
                              }}
                            >
                              {n}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                    {realestateChoice ===
                      realestateList[realestateList.length - 1] && (
                      <CustomInput
                        value={realestate}
                        onChange={(e) => {
                          setRealestate(e.target.value);
                        }}
                      />
                    )}
                  </Box>
                  <FormLabel color={"black"} htmlFor="owner">
                    Please find and type Landlord's name
                  </FormLabel>
                  <CustomInput
                    value={owner}
                    placeholder="(auto prefil) Landlord"
                    maxLength={50}
                    onChange={(e) => {
                      setOwner(e.target.value);
                    }}
                  />
                </Box>
              )}
              {ownerChoice === ownerList[2] && (
                <Box mt={2}>
                  <FormLabel color={"black"} htmlFor="owner">
                    Please find and type Landlord's name
                  </FormLabel>
                  <CustomInput
                    value={owner}
                    placeholder="(auto prefil) Landlord"
                    maxLength={50}
                    onChange={(e) => {
                      setOwner(e.target.value);
                    }}
                  />
                  <FormLabel color={"black"} htmlFor="Phone">
                    Phone number
                  </FormLabel>
                  <CustomInput
                    value={landlordPhone}
                    maxLength={50}
                    onChange={(e) => {
                      setLandLordPhone(e.target.value);
                    }}
                  />
                  <FormLabel color={"black"} htmlFor="email">
                    Email Address
                  </FormLabel>
                  <CustomInput
                    value={landlordEmail}
                    maxLength={50}
                    onChange={(e) => {
                      setLandLordEmail(e.target.value);
                    }}
                  />
                </Box>
              )}
            </Box>

            {ownerChoice !== ownerList[2] && (
              <Box w={"full"} pt={4}>
                <FormLabel htmlFor="Job No." color={"black"}>
                  Job No.
                </FormLabel>
                <CustomInput
                  value={jobNo}
                  maxLength={50}
                  onChange={(e) => {
                    setJobNo(e.target.value);
                  }}
                />
              </Box>
            )}
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="site address">
                Site Address
              </FormLabel>
              <CustomInput
                value={siteAddress}
                maxLength={50}
                onChange={(e) => {
                  setSiteAddress(e.target.value);
                }}
              />
            </Box>
          </FormControl>
        );
      case 3:
        return (
          <FormControl color={"gray.400"} isRequired>
            <Box w={"full"} pt={4}>
              <FormLabel htmlFor="Real Estate" color={"black"}>
                Who do you met with?
              </FormLabel>
              <Menu>
                <MenuButton
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  {metWithChoice}
                </MenuButton>
                <MenuList maxHeight={"50vh"} overflowY={"scroll"}>
                  {metWithTypes.map((n) => {
                    return (
                      <MenuItem
                        color={"black"}
                        key={n}
                        onClick={() => setMetWithChoice(n)}
                      >
                        {n}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
              {metWithChoice === "custom answer" && (
                <CustomInput
                  value={metWith}
                  onChange={(e) => {
                    setMetWith(e.target.value);
                  }}
                />
              )}
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel htmlFor="Met with" color={"black"}>
                What's the type of room?
              </FormLabel>
              <Menu>
                <MenuButton
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  {roomChoice}
                </MenuButton>
                <MenuList maxHeight={"50vh"} overflowY={"scroll"}>
                  {roomTypes.map((n) => {
                    return (
                      <MenuItem
                        color={"black"}
                        key={n}
                        onClick={() => setRoomChoice(n)}
                      >
                        {n}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
              {roomChoice === "custom answer" && (
                <CustomInput
                  value={roomType}
                  onChange={(e) => {
                    setRoomType(e.target.value);
                  }}
                />
              )}
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel htmlFor="Roof type" color={"black"}>
                What's the type of roof?
              </FormLabel>
              <Menu>
                <MenuButton
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  {roofChoice}
                </MenuButton>
                <MenuList maxHeight={"50vh"} overflowY={"scroll"}>
                  {roofTypes.map((n) => {
                    return (
                      <MenuItem
                        color={"black"}
                        key={n}
                        onClick={() => setRoofChoice(n)}
                      >
                        {n}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
              {roofChoice === "custom answer" && (
                <CustomInput
                  value={roofType}
                  onChange={(e) => {
                    setRoofType(e.target.value);
                  }}
                />
              )}
            </Box>
            <Box pt={6}>
              <PrimaryButton
                onClick={generateMakeSafeDescription}
                title="Prefill"
              />
            </Box>
            <Textarea
              mt={6}
              h={250}
              color={"black"}
              value={makeSafeDescription}
              focusBorderColor="black"
              onChange={(e) => {
                setMakeSafeDescription(e.target.value);
              }}
            />
          </FormControl>
        );
      default:
        return <Box></Box>;
    }
  };

  const addImageProcess = (
    file: File
  ): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      let newImage = new Image();
      newImage.src = window.URL.createObjectURL(file);
      newImage.onload = () =>
        resolve({ height: newImage.height, width: newImage.width });
      newImage.onerror = reject;
    });
  };
  const getRealestateText = (
    choice: string,
    strataChoice: string,
    realestateChoice: string,
    email: string
  ) => {
    if (ownerList[0] === choice) {
      return strataChoice === strataList[strataList.length - 1]
        ? strata
        : strataChoice;
    }
    if (choice === ownerList[1]) {
      return realestateChoice === realestateList[realestateList.length - 1]
        ? realestate
        : realestateChoice;
    }

    return email;
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        date: moment(date).format("DD MMMM YYYY"),
        siteAddress,
        jobNo: jobNo,
        type: "Make Safe Order",
        sections: imageUrls.map((n) => {
          return {
            description: "",
            imageUrl: n.imageUrl,
            width: n.width,
            height: n.height,
          };
        }),
        owner:
          ownerChoice === ownerList[0]
            ? `The Owners--Strata Plan ${strataPlan}`
            : ownerChoice === ownerList[1]
            ? `${owner || "Landlord"}`
            : `${owner || "Landlord"} ${
                landlordPhone ? `(${landlordPhone})` : ""
              }`,
        realestate: getRealestateText(
          ownerChoice,
          strataChoice,
          realestateChoice,
          landlordEmail
        ),
        makeSafeDescription,
      };
      await addQuote(payload);
      alert("Your MakeSafeOrder has been submitted!");

      setOpen(true);
    } catch (e: any) {
      if (e.message) {
        alert(e.message);
      }

      const error = apiErrorHandler(e);
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const goToQuote = () => {
    const data = {
      date,
      owner,
      jobNo,
      siteAddress,
      ownerChoice,
      landlordPhone,
      strataChoice,
      strata,
      realestate,
      realestateChoice,
      landlordEmail,
      strataPlan,
      step: 1,
      jobDescription: [
        {
          title: "",
          description:
            "1. Supply labour to set up and use harnesses, ropes, and temporary anchor points to facilitate works as required to comply with OH&S.",
        },
      ],
    };
    window.localStorage.setItem(cacheKey, JSON.stringify(data));
    setOpen(false);
    setTimeout(() => {
      navigate("/quote");
    }, 1000);
  };
  return (
    <Stack
      overflow={"hidden"}
      borderRadius={12}
      direction={"row"}
      boxShadow={"lg"}
      w={"100%"}
      background={"white"}
      maxWidth={"528px"}
      m={"auto"}
      mt={6}
      pb={12}
    >
      <Modal
        size={"sm"}
        isCentered
        onClose={() => {
          setOpen(false);
        }}
        isOpen={open}
      >
        <ModalOverlay background={"transparent"} />

        <ModalContent alignItems={"center"} py={8} background={"white"}>
          <ModalCloseButton m={2} size={"md"} />
          <Text mt={2}>Do you want to submit a quote for this order?</Text>
          <Stack direction={"row"} gap={4} w={"full"} px={4} pt={8}>
            <PrimaryButton onClick={() => goToQuote()} title="YES" />
            <Button
              borderRadius={"12px"}
              boxShadow={"lg"}
              w={"full"}
              py={7}
              colorScheme="blackAlpha"
              onClick={() => navigate("/")}
            >
              No
            </Button>
          </Stack>
        </ModalContent>
      </Modal>
      <Box p={6} py={12} flex={1}>
        <Box p={4} margin={"auto"} maxW={"360px"}>
          <Text variant={"title_2xl"} textAlign={"center"} pb={6}>
            Submit a Make Safe Order
          </Text>
        </Box>
        <Progress colorScheme="green" size="md" value={(step / 3) * 100} />
        <Stack flex={1} align={"center"}>
          {renderContent()}
          <Stack
            pb={40}
            w={"full"}
            justifyContent={"space-between"}
            direction={"row"}
            pt={6}
          >
            <Button
              borderRadius={"12px"}
              boxShadow={"lg"}
              w={"full"}
              py={7}
              colorScheme="blackAlpha"
              disabled={step <= 1}
              onClick={() => setStep(step - 1)}
            >
              Back
            </Button>
            <PrimaryButton
              isLoading={isLoading}
              onClick={() => (step <= 2 ? setStep(step + 1) : handleSubmit())}
              disabled={isNextDisabled()}
              title={step <= 2 ? "Next" : "Submit"}
            />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
