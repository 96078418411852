import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import {
  Tracking,
  addTracking,
  deleteTracking,
  getTrackings,
  updateTracking,
} from "../services/api/tracking";
import { useEffect } from "react";
import { authApiErrorHandler } from "../services/api/httpClient";
import { useNavigate } from "react-router-dom";
import { Stack, useToast } from "@chakra-ui/react";
import { ThemeProvider, colors, createTheme } from "@mui/material";

import { randomId } from "@mui/x-data-grid-generator";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      { id, isNew: true, jobType: "", creditor: "", status: "UNPAID" },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "date" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export default function Trackings() {
  const [rows, setRows] = React.useState<Tracking[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  const handleAddTracking = async (data: Tracking) => {
    try {
      const response = await addTracking(data);
      return response;
    } catch (e) {
      const error = authApiErrorHandler(e, navigate);
      toast({
        title: "Create Tracking Failed",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleUpdateTracking = async (data: Tracking) => {
    try {
      const response = await updateTracking(data);
      return response;
    } catch (e) {
      const error = authApiErrorHandler(e, navigate);
      toast({
        title: "Update Tracking Failed",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleDeleteTracking = async (id: number) => {
    try {
      await deleteTracking(id);
    } catch (e) {
      const error = authApiErrorHandler(e, navigate);
      toast({
        title: "Delete Row Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleGetTracking = async () => {
    try {
      setLoading(true);
      const response = await getTrackings();
      setRows(response);
    } catch (e) {
      const error = authApiErrorHandler(e, navigate);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetTracking();
  }, []);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (data: any) => () => {
    setRowModesModel({
      ...rowModesModel,
      [data.id]: { mode: GridRowModes.View },
    });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    await handleDeleteTracking(id as number);
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if ((editedRow as any)!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    let res: Tracking | undefined;
    if (newRow.isNew) {
      res = await handleAddTracking(newRow as Tracking);
    } else {
      await handleUpdateTracking(newRow as Tracking);
      res = newRow as Tracking;
    }

    const updatedRow = { ...res, isNew: false };
    setRows(
      (rows as any).map((row: any) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "Date",
      type: "date",
      hideable: false,
      width: 150,
      sortable: false,
      editable: true,
      valueGetter: (params) => new Date(params.row.date),
    },
    {
      hideable: false,
      sortable: false,
      disableColumnMenu: true,
      field: "address",
      headerName: "Property Address",
      minWidth: 550,
      flex: 1,
      editable: true,
      filterable: false,
    },
    {
      hideable: false,
      sortable: false,
      field: "jobType",
      headerName: "Job Type",
      width: 140,
      editable: true,
      type: "singleSelect",
      valueOptions: [" ", "Approved Job", "Gutter clearing", "Make Safe"],
    },
    {
      hideable: false,
      sortable: false,
      disableColumnMenu: true,
      field: "invoiceNo",
      headerName: "Invoice Number",
      width: 140,
      editable: true,
      filterable: false,
    },

    {
      hideable: false,
      sortable: false,
      field: "creditor",
      headerName: "Creditor",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        " ",
        "Clisdells",
        "Absolute Strata",
        "Ballard",
        "BresicW",
        "Ling Siu",
        "Evangeline Tighe",
        "McGrath S/H",
        "Bresic",
        "PropertyCo",
        "Lisa Steele ",
        "George Harris",
        "RayWhite",
        "Michael Roberts",
      ],
    },
    {
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      field: "pm",
      headerName: "PM",
      width: 140,
      editable: true,
      filterable: false,
    },
    {
      hideable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      headerAlign: "left",
      field: "amount",
      headerName: "Amount Due",
      type: "number",
      width: 140,
      renderCell: (params) => <div>{parseInt(params.row.amount)}</div>,
      editable: true,
      filterable: false,
    },
    {
      hideable: false,
      sortable: false,
      disableColumnMenu: true,
      field: "materialFee",
      headerName: "Materials",
      type: "number",
      width: 140,
      headerAlign: "left",
      align: "left",
      editable: true,
      renderCell: (params) => <div>{parseInt(params.row.materialFee)}</div>,
      filterable: false,
    },
    {
      sortable: false,
      field: "noGstAndMaterials",
      headerName: "No materials and GST",
      hideable: false,
      width: 140,
      headerAlign: "left",
      align: "left",
      editable: false,
      filterable: false,
      renderCell: (params) => (
        <div>
          {(
            (parseFloat(params.row.materialFee || "0") +
              parseFloat(params.row.amount || "0")) /
            1.1
          ).toFixed(0)}
        </div>
      ),
    },
    {
      sortable: false,
      hideable: false,
      field: "status",
      headerName: "Status",
      width: 140,
      editable: true,
      headerAlign: "left",
      align: "left",
      type: "singleSelect",
      valueOptions: ["PAID", "UNPAID"],
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      field: "notes",
      headerName: "Notes",
      width: 200,
      filterable: false,
      editable: true,
    },
    {
      hideable: false,
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, ...other }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(other.row)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  const MuiTheme = createTheme({
    palette: {
      mode: "light",
    },
    components: {},
  });

  return (
    <Box
      sx={{
        height: 500,
        padding: "30px",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Stack
        direction={"row"}
        gap={10}
        // justifyContent={"center"}
        alignItems={"center"}
      >
        <Box my={2} fontSize={20}>
          Total Due: ${" "}
          {rows
            .filter((n) => n.status === "UNPAID")
            .reduce((pre, next) => {
              return parseFloat((next.amount as any) || "0") + pre;
            }, 0)}
        </Box>
        {/* <Box my={2} fontSize={20}>
          Total Amount:{" "}
          {rows.reduce((pre, next) => {
            return parseFloat((next.amount as any) || "0") + pre;
          }, 0)}
        </Box> */}
      </Stack>
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          sx={{ height: "75vh" }}
          initialState={{
            filter: {
              filterModel: {
                items: [{ field: "status", operator: "is", value: "UNPAID" }],
              },
            },
          }}
          rows={rows}
          loading={loading}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      </ThemeProvider>
    </Box>
  );
}
