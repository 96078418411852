import axios, { AxiosInstance, Method } from "axios";
import { NavigateFunction } from "react-router-dom";
import { destroyToken, getToken } from "./util";

export enum HttpStatus {
  Ok = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  Conflict = 409,
  NotFound = 404,
  Unprocessable = 422,
  Internal = 500,
}
export interface Header {
  [key: string]: string;
}

export interface QueryParams {
  [key: string]: string;
}

export interface RequestBody {
  [key: string]: any;
}

export interface URLSearchParams {
  [key: string]: unknown;
}

export interface HttpClientParams {
  method: Method;
  url: string;
  headers?: Header;
  queryParams?: QueryParams;
  data?: RequestBody;
}
export interface ApiError {
  status: HttpStatus;
  message: string;
  data: any;
}
export const apiErrorHandler = (error: any): ApiError => {
  try {
    return {
      status: error.response.status || error.response.data.status,
      message: error.response.message || error.response.data.message,
      data: error.response.data || error.response.data.data,
    };
  } catch (e) {
    console.log(e);
    return {
      status: HttpStatus.Internal,
      message: "Unknown Error",
      data: "Unknown Error",
    };
  }
};

export const authApiErrorHandler = (
  error: any,
  navigate: NavigateFunction
): ApiError => {
  try {
    const status = error.response.status || error.response.data.status;
    destroyToken();
    if (status === HttpStatus.Unauthorized) {
      navigate("/login");
    }
    return {
      status: status,
      message: error.response.message || error.response.data.message,
      data: error.response.data || error.response.data.data,
    };
  } catch (e) {
    console.log(e);
    return {
      status: HttpStatus.Internal,
      message: "Unknown Error",
      data: "Unknown Error",
    };
  }
};

class HttpClient {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_HOST,
      timeout: 60000 * 5,
    });
  }
  init() {
    this.instance.interceptors.request.use((config) => {
      const token = getToken();
      if (config.headers) {
        // config.headers.Accepted = "application/json";
        config.headers["Cache-Control"] = "no-cache, no-store, must-revalidate";
        config.headers["Pragma"] = "no-cache";
        config.headers["Expires"] = "0";
      }
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        delete config?.headers?.["Authorization"];
      }

      return config;
    });
  }
  async sendRequest(params: HttpClientParams) {
    const { method, url, headers, queryParams, data } = params;
    try {
      const response = await this.instance.request({
        url: `${url}`,
        method: method,
        headers: headers,
        params: queryParams,
        data: data || undefined,
      });

      return response;
    } catch (e) {
      throw e;
    }
  }
}

const httpClient = new HttpClient();
httpClient.init();
export { httpClient };
