import {
  extendTheme,
  withDefaultColorScheme,
  theme as baseTheme,
} from "@chakra-ui/react";

const customTheme = extendTheme(
  {
    components: {
      Text: {
        variants: {
          title_l: {
            fontFamily: [`"Space Grotesk", "sans-serif"`],
            fontSize: "l",
          },
          title_md: {
            fontFamily: [`"Space Grotesk", "sans-serif"`],
            fontSize: "md",
          },
          title_sm: {
            fontFamily: [`"Space Grotesk", "sans-serif"`],
            fontSize: "sm",
            fontWeight: "medium",
          },
          title_xs: {
            fontFamily: [`"Space Grotesk", "sans-serif"`],
            fontSize: "xs",
            fontWeight: "medium",
          },
          title_xl: {
            fontFamily: [`"Space Grotesk", "sans-serif"`],
            fontSize: "xl",
          },
          title_2xl: {
            fontFamily: [`"Space Grotesk", "sans-serif"`],
            fontSize: "2xl",
          },
          title_3xl: {
            fontFamily: [`"Space Grotesk", "sans-serif"`],
            fontSize: "3xl",
            fontWeight: "bold",
          },
          heading: {
            fontFamily: [`"Space Grotesk", "sans-serif"`],
            fontSize: "4xl",
            fontWeight: "bold",
          },
          caption: {
            fontFamily: [`"Space Grotesk", "sans-serif"`],
            fontSize: "sm",
            color: "gray.500",
            fontWeight: "thin",
          },
          caption2: {
            fontFamily: [`"Space Grotesk", "sans-serif"`],
            fontSize: "xs",
            color: "gray.500",
            fontWeight: "thin",
          },
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "brand" })
);

export default customTheme;
