import { httpClient } from "./httpClient";
import { User } from "./user";

export interface Quote {
  id: number;
  userId: string;
  date: string;
  link: null;
  owner: string;
  jobNo: string;
  realestate: string;
  siteAddress: string;
  observation: string;
  jobDescriptions: JobDescription[];
  sections: Section[];
  createdAt: Date;
  updatedAt: Date;
  User: User;
  isDownloaded: boolean;
  warrantyClaim: string;
  warrantyNote: string;
  type: string;
  makeSafeDescription: string;
}

export interface JobDescription {
  title: string;
  description: string;
  price?: number;
}

export interface Section {
  imageUrl: string;
  description: string;
}

export async function addQuote(data: any): Promise<string> {
  const response = await httpClient.sendRequest({
    url: "quotes",
    method: "POST",
    data: data,
  });
  return response.data;
}

export async function downloadQuote(id: number): Promise<number> {
  const response = await httpClient.sendRequest({
    url: `quotes/${id}/download`,
    method: "POST",
  });
  return response.data;
}

export async function getQuotes(): Promise<Quote[]> {
  const response = await httpClient.sendRequest({
    url: "quotes/all",
    method: "GET",
  });
  return response.data;
}
